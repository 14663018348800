.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 600px;
    input {
        width: 600px;
    }
    textarea {
        width: 600px;
        height: 100px;
    }
}