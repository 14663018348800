.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 2rem;

    .p-dropdown {
        width: 300px;
    }

    .buttonsWrapper {
        display: flex;
        gap: 1rem;
    }
}