.loginWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .logoWrapper {
        display: flex;
        align-items: center;
        margin-bottom: 80px;

        img {
            width: 100px;
        }
        h1 {
            margin: 0;
            margin-left: 10px;
            font-size: 33px;
        }
    }

    .loginForm {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        input {
            width: 300px;
        }
    }
}