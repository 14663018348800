
.outerContentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;

    .contentWrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin-top: 5rem;

        .pair {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            gap: 1rem;
        }
    }
}