.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 2rem;

    input {
        width: 300px;
    }

    .newPasswordWrapper {
        width: 300px;
        display: flex;
        align-items: baseline;

        input {
            width: 100%;
        }
    }

    .buttonsWrapper {
        display: flex;
        gap: 1rem;
    }
}