@import '../../variables.scss';

.sidebarWrapper {
    min-width: 250px;
    box-sizing: border-box;
    padding: 20px;
    font-family: $font-family;
    font-weight: $font-weight;

    .imageWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        flex-grow: 1;
        margin-bottom: 40px;
        
        img {
            width: 50px;
        }
        h1 {
            margin: 0;
            margin-left: 10px;
        }
    }

    .linksWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        a {
            text-decoration: none;
            color: $text-color;
            transition-duration: $transition-duration;
            font-size: $font-size;
            display: flex;
            align-items: center;
            gap: 10px;

            i {
                font-size: 24px;
            }
        }
        a:hover {
            color: $active-color;
        }
        a.active {
            color: $active-color;
        }
    }
}