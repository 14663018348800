.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 2rem;

    .input {
        width: 300px;
    }

    .alignLeft {
        align-self: flex-start;
    }

    .radioWrapper {
        display: flex;
        align-items: baseline;
        height: 35px;
        width: 300px;
        gap: 8px;

        .toWrapper {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .input {
                width: 100%;
            }
        }
    }

    .buttonsWrapper {
        display: flex;
        gap: 1rem;
    }
}