@import '../../variables.scss';

.rootWrapper {
    display: flex;
    flex-grow: 1;

    .outletContentWrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        
        .innerContentWrapper {
            flex-grow: 1;
            padding: 27px 20px 20px 20px;
            background-color: $background-color;
        
            h1 {
                margin: 0;
            }
        }
    }
}