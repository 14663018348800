.detailsWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;

    .leftWrapper {
        margin-right: 6rem;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
    }
    .column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
        .pair {
            display: flex;
            align-items: center;
            gap: .5rem;
            min-height: 35px;
            font-size: 1.2rem;

            .label {
                font-weight: bold;
            }
        }
    }
    .rightWrapper {
        margin-bottom: 2rem;
    }
}