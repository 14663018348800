@import '../../variables.scss';

.footerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    box-sizing: border-box;
    background-color: #F2F4F6;
    font-weight: $font-weight;

    .footerSection {
        display: flex;
        align-items: center;

        img {
            height: 64px;
            margin: 0 10px;
        }
    }
}